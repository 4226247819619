import React, { useEffect } from "react";
import classes from "./privacypolicy.module.css";
import { useNavigate } from "react-router-dom";
const PrivacyPolicy = () => {
    const navigate = useNavigate();
    const _token = JSON.parse(localStorage.getItem("userToken"));
    useEffect(() => {
        if (!_token) {
            navigate('/login')
        }
    })
  return (
    <div className={classes.privacypolicy_container}>
      <header className={classes.header}>
        <h1 className="fs-2 text-center">Privacy Policy</h1>
        <p>
          <strong>Effective Date:</strong> 12/10/2024
        </p>
        <p>
          <strong>Last Updated:</strong> 23/11/2024
        </p>
      </header>

      <section>
        <p>
          At <strong>MyGenio</strong> we
          prioritize your privacy and are committed to protecting the
          information you share with us. This Privacy Policy explains how we
          collect, use, and safeguard your information when you use our website
          <a href="https://app.mygenio.ai/">https://app.mygenio.ai/</a> and our
          AI-powered assistance tools.
        </p>
      </section>

      <section>
        <h2 className="fs-4">1. Information We Collect</h2>
        <h3 className="fs-5">a. Personal Information</h3>
        <p>
          When you connect your email accounts (e.g., Gmail or Webmail) to our
          platform, we may collect:
        </p>
        <ul>
          <li>Email address</li>
          <li>Messages and email content</li>
          <li>Contact list (if required for functionality)</li>
        </ul>

        <h3 className="fs-5">b. Usage Data</h3>
        <p>
          We automatically collect certain information when you access our
          website or services, including:
        </p>
        <ul>
          <li>Device type, browser, and operating system</li>
          <li>IP address</li>
          <li>Usage patterns (e.g., time spent, features accessed)</li>
        </ul>

        <h3 className="fs-5">c. AI Interactions</h3>
        <p>When you use our AI chat assistant, we collect:</p>
        <ul>
          <li>
            Queries and conversations for the purpose of providing accurate
            responses and improving our services.
          </li>
        </ul>
      </section>

      <section>
        <h2 className="fs-5">2. How We Use Your Information</h2>
        <p>We use the collected information to:</p>
        <ul>
          <li>Analyze and process your emails for AI assistance.</li>
          <li>
            Provide personalized responses and support through our chat tools.
          </li>
          <li>Improve and optimize our AI models and user experience.</li>
          <li>
            Communicate important updates, features, or changes to our services.
          </li>
        </ul>
      </section>

      <section>
        <h2 className="fs-5">3. Third-Party Integrations</h2>
        <p>We may integrate with third-party services, including:</p>
        <ul>
          <li>
            <strong>Email providers:</strong> To connect your Gmail or Webmail
            accounts.
          </li>
          <li>
            <strong>AI processing tools:</strong> For interpreting and assisting
            with your queries.
          </li>
        </ul>
        <p>
          These integrations comply with their respective privacy policies, and
          we do not share your data without consent.
        </p>
      </section>

      <section>
        <h2 className="fs-5">4. Data Security</h2>
        <p>
          We implement industry-standard measures to safeguard your data,
          including:
        </p>
        <ul>
          <li>Encryption for email data during transmission.</li>
          <li>Secure storage systems to prevent unauthorized access.</li>
          <li>Regular monitoring and updates to our security protocols.</li>
        </ul>
      </section>

      <section>
        <h2 className="fs-5">5. Data Sharing</h2>
        <p>
          We do not sell or share your personal information with third parties,
          except:
        </p>
        <ul>
          <li>
            <strong>For service functionality:</strong> To provide email
            analysis and AI assistance.
          </li>
          <li>
            <strong>When required by law:</strong> To comply with legal
            obligations or enforce agreements.
          </li>
        </ul>
      </section>

      <section>
        <h2 className="fs-5">6. Your Rights</h2>
        <p>You have the right to:</p>
        <ul>
          <li>Access, update, or delete your personal data.</li>
          <li>Revoke consent for connecting email accounts at any time.</li>
          <li>Request data portability.</li>
        </ul>
        <p>
          To exercise these rights, contact us at{" "}
          <a href="mailto:mygeniolive@gmail.com">mygeniolive@gmail.com</a>.
        </p>
      </section>

      <section>
        <h2 className="fs-5">7. Cookies and Tracking</h2>
        <p>We use cookies and similar technologies to:</p>
        <ul>
          <li>Track website usage for analytics.</li>
          <li>Enhance your user experience.</li>
        </ul>
        <p>You can manage cookie preferences through your browser settings.</p>
      </section>

      <section>
        <h2 className="fs-5">8. Third-Party Links</h2>
        <p>
          Our website may contain links to third-party websites. We are not
          responsible for their privacy practices and recommend reviewing their
          privacy policies.
        </p>
      </section>

      <section>
        <h2 className="fs-5">9. Children's Privacy</h2>
        <p>
          Our services are not intended for individuals under the age of 13. We
          do not knowingly collect information from children. If we become aware
          of such data, we will delete it.
        </p>
      </section>

      <section>
        <h2 className="fs-5">10. Updates to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. Changes will be
          communicated by updating the "Last Updated" date above. Continued use
          of our services indicates acceptance of the updated policy.
        </p>
      </section>

      <section>
        <h2 className="fs-5">11. Contact Us</h2>
        <p>
          If you have questions or concerns about this Privacy Policy, contact
          us at:
        </p>
        <p>
          <strong>Email:</strong>{" "}
          <a href="mailto:mygeniolive@gmail.com">mygeniolive@gmail.com</a>
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
