import {
  Box,
  Typography,
  Paper,
  Button,
  TextareaAutosize,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { TypeAnimation } from "react-type-animation";
import classes from "./email.module.css";
import {
  deleteGmail,
  deleteManyGmail,
  getMyEmailMessages,
  getMyEmails,
  getOneemail,
  getsuggestionMessage,
  sendReplayMessage,
  sendsuggestionMessage,
} from "../../api/Api";
import EmailSubSideBar from "./EmailSubSideBar";
import EmailMessageSubSideBar from "./EmailMessageSubSidebar";
import { errorState } from "../../redux/reducers/UserReducer";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { BiSend } from "react-icons/bi";
import mqtt from "mqtt";
import { FormControl, FormLabel } from "react-bootstrap";
import { Input } from "rsuite";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { set } from "rsuite/esm/internals/utils/date";
import ComposeMail from "../compose";
import AiMailModal from "../compose/AiMailModal";
import Aicomponent from "../aiComposeComponent/Aicomponent";
import forward_img1 from "../../assets/icons/forward1.svg";
import forward_img2 from "../../assets/icons/forward2.png";
import forward_img3 from "../../assets/icons/forward3.png";
import send_icon from "../../assets/icons/Send Icon.svg";
import bin_icon from "../../assets/icons/bin_icon.svg";
import print_icon from "../../assets/icons/print_icon.svg";
import delete_icon from "../../assets/icons/delete_icon.svg";
import { toast } from "react-toastify";
import { RotatingLines } from "react-loader-spinner";
// Utility function to check if a string contains HTML tags
const containsHtml = (str) => {
  const doc = new DOMParser().parseFromString(str, "text/html");
  return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1); // Check for element nodes
};

const EmailIndex = () => {
  const [value, setValue] = useState("");
  const [aiComponent, setAiComponent] = useState(false);
  const [getMailDetall, setGetMailDetail] = useState({});
  const [message, setMessage] = useState([]);
  const [MoreEmailId, setMoreEmailId] = useState([]);
  const dispatch = useDispatch();
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deletedEmailId, setDeletedEmailId] = useState(""); // For tracking deleted email
  const [emailsList, setEmailsList] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState();
  const [emailMessageList, setEmailMessageList] = useState([]);
  const [oneEmail, setOneEmail] = useState();
  const [selectedEmail, setSelectedEmail] = useState();
  const [secSidebaropen, setSecSidebaropen] = useState(false);
  const [manyLoading, setmanyLoading] = useState(false);
  const [suggestiondata, setSuggestiondata] = useState([]);
  const [questionSide, setQustionSide] = useState(false);
  const [qusetion, setQusetion] = useState([]);
  const [formData, setFormData] = useState({});
  const [options, setOptions] = useState("");
  const [draftLoading, setDraftLoading] = useState(false);
  const [draftMessage, setDraftMessage] = useState(null);
  const [composeOpen, setComposeOpen] = useState(false);
  const [copy, setCopy] = useState(null);
  const [mailDetail, setMailDetail] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [firstMessage, setFirstMessage] = useState("");
  const [replayLoading, setReplayLoading] = useState(false);
  useEffect(() => {
    const userToken = localStorage.getItem("userToken");
    if (userToken) {
      try {
        const user = JSON.parse(userToken); // Assuming userToken is a JSON string
        setCurrentUser(user._id); // Set the current user ID
      } catch (error) {
        console.log("Error parsing user token:", error);
      }
    }
  }, []);
  const handleGetEmails = async () => {
    let result = await getMyEmails();
    if (result?.status === 201 || result?.status === 200) {
      setEmailsList(result?.data?.data);
    } else {
      dispatch(
        errorState(
          result?.data?.message ? result?.data?.message : result?.statusText
        )
      );
    }
  };

  const handleGetEmailMessages = async () => {
    let result = await getMyEmailMessages(selectedEmail);
    if (result?.status === 201 || result?.status === 200) {
      setEmailMessageList(result?.data?.data);
    } else {
      dispatch(
        errorState(
          result?.data?.message ? result?.data?.message : result?.statusText
        )
      );
    }
  };

  const handelDeleteEmail = async (id) => {
    try {
      setLoading(id);
      await deleteGmail(id);
      setEmailsList((prevEmails) =>
        prevEmails.filter((email) => email.id !== id)
      );
      if (selectedMessage?.email_id === id) {
        setSelectedMessage(null);
        setOneEmail(null);
      }
      setDeletedEmailId(id);
      setLoading(null);
    } catch (error) {
      setLoading(null);
      console.log(error);
    }
  };
  function convertNewlinesToBr(text) {
    return text.replace(/\n/g, "<br>");
  }
  const handleGetOneEmail = async () => {
    const sendData = {
      id: selectedMessage?.email_id,
      email: selectedMessage?.email,
    };
    let result = await getOneemail(sendData);
    const length = result?.data?.data?.length;
    var data;
    if (result?.data?.data[length - 1]?.textContent) {
      data = {
        email_id: selectedMessage?.email_id,
        message: result?.data?.data[length - 1].textContent,
        email: selectedMessage?.email,
      };
    } else {
      data = {
        email_id: selectedMessage?.email_id,
        message: result?.data?.data[length - 1].htmlContent,
        email: selectedMessage?.email,
      };
    }
    console.log(data);
    const currentEmail = result?.data?.data[length - 1].current_mail;
    const fromMail = result?.data?.data[length - 1].from;

    // Extract the email address from the 'fromMail' string
    const emailRegex = /<([^>]+)>/; // Regex to capture the email inside angle brackets
    const extractedEmailMatch = fromMail.match(emailRegex);
    const extractedEmail = extractedEmailMatch
      ? extractedEmailMatch[1]
      : fromMail; // Fallback if no angle brackets

    if (currentEmail !== extractedEmail) {
      const responce = await getsuggestionMessage(data);
    }

    if (result?.status === 201 || result?.status === 200) {
      console.log("we send the gmail and the email _id pls wait for a while");
    }
    if (result?.status === 201 || result?.status === 200) {
      console.log(result?.data?.data);
      setOneEmail(result?.data?.data);
    } else {
      dispatch(
        errorState(
          result?.data?.message ? result?.data?.message : result?.statusText
        )
      );
    }
  };

  useEffect(() => {
    handleGetEmails();
  }, [deletedEmailId]); // Fetch emails after deletion

  useEffect(() => {
    if (selectedEmail) handleGetEmailMessages();
  }, [selectedEmail, deletedEmailId]); // Fetch messages after deletion

  useEffect(() => {
    if (selectedMessage) {
      handleGetOneEmail();
    }
  }, [selectedMessage]);

  const handelsuggestion = (data, i) => {
    // setValue(data);
    console.log(i);
    setOptions(data);
    setQusetion(suggestiondata[i].questions);
    setQustionSide(true);
    // console.log(suggestiondata[i]);
  };

  const handleManyDelete = async () => {
    try {
      setmanyLoading(true);
      await deleteManyGmail(MoreEmailId);
      await handleGetEmailMessages();
      setmanyLoading(false);
    } catch (error) {
      setmanyLoading(false);
      console.log(error);
    }
  };
  function formatToJSON(data) {
    // Join the array into a single string
    const dataString = data.join(",");

    // Replace the "options" and parse the string to a valid JSON
    const formattedData = dataString
      .replace(/options:/, '"options":')
      .replace(/([{,])(\s*)(questions)/g, '$1$2"$3"')
      .replace(/([\]}])(\s*),/g, "$1,") // Remove trailing commas before closing braces
      .replace(/"/g, '"'); // Ensure proper quotes

    return JSON.parse(formattedData);
  }
  useEffect(() => {
    // Connect to MQTT broker
    const client = mqtt.connect(
      "wss://b50ce94deaad43e698243faccb466fe5.s1.eu.hivemq.cloud:8884/mqtt",
      {
        clientId: `clientId-${Math.random().toString(16).substr(2, 8)}`,
        username: "mjraghavan",
        password: "j!v#!AjRyaL62Du",
      }
    );

    // Subscribe to the topic once connected
    client.on("connect", () => {
      console.log("Connected to MQTT broker");
      client.subscribe(
        `suggestions/${selectedMessage?.email_id}`,
        { qos: 1 },
        (error) => {
          if (error) {
            console.error("Subscription error:", error);
          } else {
            console.log(
              `Subscribed to topic: suggestions/${selectedMessage?.email_id}`
            );
          }
        }
      );

      client.subscribe(
        `draft/${selectedMessage?.email_id}`,
        { qos: 1 },
        (error) => {
          if (error) {
            console.error("Subscription error:", error);
          } else {
            console.log(
              `Subscribed to topic: draft/${selectedMessage?.email_id}`
            );
          }
        }
      );
    });

    client.on("message", (topic, message) => {
      console.log("Received message:", message.toString());

      // Split the message into an array
      const messageArray = message.toString().split(",");

      // Format the incoming message to JSON
      try {
        // Check the topic and set state accordingly
        if (topic.startsWith("suggestions/")) {
          const data = formatToJSON(messageArray);
          console.log(data);
          setSuggestiondata(data.options);
          const filterData = data.options.map((data) => data.option);
          setMessage(filterData);
        }
        if (topic.startsWith("draft/")) {
          setDraftLoading(true);
          try {
            // Parse if it's JSON
            setDraftMessage(JSON.parse(message.toString()));
          } catch (error) {
            console.error("Error parsing draft message:", error);
          } finally {
            setDraftLoading(false); // Reset loading state after processing
          }
        }
      } catch (error) {
        console.error("Error formatting message to JSON:", error);
        setDraftLoading(false); // Reset loading state on error
      }
    });

    client.on("error", (err) => {
      console.error("MQTT Client Error:", err);
      setDraftLoading(false); // Reset loading state on connection error
    });

    // Cleanup the MQTT client on component unmount or when email_id changes
    return () => {
      client.end();
    };
  }, [selectedMessage]); // Make sure to include selectedMessage as a dependency

  const handleInputChange = (index, event) => {
    const { value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [index]: value,
    }));
  };

  const handleSubmit = async () => {
    const result = qusetion.map((question, index) => ({
      question,
      answer: formData[index] || "",
    }));
    const length = oneEmail.length;
    var data;
    if (oneEmail[length - 1].htmlContent) {
      data = {
        email_id: selectedMessage?.email_id,
        result: result,
        message: oneEmail[length - 1].htmlContent,
      };
    } else {
      data = {
        email_id: selectedMessage?.email_id,
        result: result,
        message: oneEmail[length - 1].htmlContent,
      };
    }

    console.log("Form Submission:", data);
    try {
      setDraftLoading(true);
      await sendsuggestionMessage(data);
    } catch (error) {
      console.log(error);
    }
  };
  console.log(draftMessage, "draftMessage");

  function separateEmailContent(draftMessage) {
    // Split the email content by newline characters
    return draftMessage.split("\n");
  }

  const handleAdd = () => {
    setValue(draftMessage);
  };
  const handleSendMail = () => {
    setReplayLoading(true);
    const sendvalue = {
      email_id: selectedMessage?.email_id,
      content: value,
      mail: selectedMessage?.email,
    };
    try {
      sendReplayMessage(sendvalue);
      toast.success("Reply email sent successfully");
      setReplayLoading(false);
    } catch (error) {
      setReplayLoading(false);
      toast.error("Error sending reply email");
      console.log(error);
    }
  };
  function removeGmailQuote(htmlContent) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");

    // Select and remove all elements with the class 'gmail_quote'
    const gmailQuotes = doc.querySelectorAll(".gmail_quote");
    gmailQuotes.forEach((quote) => quote.remove());

    // Return the cleaned HTML as a string
    return doc.body.innerHTML;
  }

  return (
    <>
      <Box className={classes?.MainContainer}>
        <EmailSubSideBar
          emailsList={emailsList}
          setSelectedEmail={setSelectedEmail}
          setSecSidebaropen={setSecSidebaropen}
          secSidebaropen={secSidebaropen}
          setComposeOpen={setComposeOpen}
          selectedEmail={selectedEmail}
        />
        {selectedEmail && (
          <EmailMessageSubSideBar
            setDeletedEmails={setDeletedEmailId}
            loading={loading}
            handelDeleteEmail={handelDeleteEmail}
            emailsList={emailMessageList}
            setSelectedEmail={setSelectedMessage}
            setSecSidebaropen={setSecSidebaropen}
            secSidebaropen={secSidebaropen}
            handleManyDelete={handleManyDelete}
            MoreEmailId={MoreEmailId}
            manyLoading={manyLoading}
            setMoreEmailId={setMoreEmailId}
          />
        )}
        {
          // composeOpen
          <>
            <Box className={classes.content}>
              {composeOpen ? (
                <ComposeMail
                  open={composeOpen}
                  setOpen={setComposeOpen}
                  mailDetail={mailDetail}
                  setAiComponent={setAiComponent}
                  setGetMailDetail={setGetMailDetail}
                />
              ) : oneEmail ? (
                <>
                  <div className={classes.emailViewer_container}>
                    {oneEmail.map((data) => {
                      return (
                        <>
                          <Paper className={classes.emailViewer_details_h}>
                            <Box className={classes.emailHeader}>
                              <div className="d-flex flex-column align-items-start gap-1">
                                <Typography
                                  gutterBottom
                                  className={classes.sub_heading_detail}
                                >
                                  {data.subject}
                                </Typography>
                                <Typography
                                  className={classes.from_heading_detail}
                                >
                                  <span style={{ color: "#707991" }}>
                                    From:
                                  </span>{" "}
                                  {data.from.split("<")[0]}
                                </Typography>
                                <Typography
                                  className={classes.to_heading_detail}
                                >
                                  <span style={{ color: "#707991" }}>To:</span>{" "}
                                  {data.to.split("<")[0]}
                                </Typography>
                              </div>
                              <div className="d-flex flex-column align-items-end justify-content-between">
                                <div className="d-flex gap-2">
                                  <Typography
                                    fontSize={12}
                                    color="textSecondary"
                                  >
                                    {new Date(data.time).toLocaleString()}
                                  </Typography>
                                  <BsThreeDotsVertical
                                    size={24}
                                    color="#707991"
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                                <div className="d-flex gap-5">
                                  <div
                                    className={classes.email_detail_frd_img1}
                                  >
                                    <div>
                                      <img
                                        src={print_icon}
                                        alt=""
                                        style={{ cursor: "pointer" }}
                                      />
                                    </div>
                                    <div
                                      className="d-flex align-items-center"
                                      style={{ gap: "12px" }}
                                    >
                                      <img
                                        src={delete_icon}
                                        alt=""
                                        style={{ cursor: "pointer" }}
                                      />
                                      <div
                                        className={classes.normal_line1}
                                      ></div>
                                      <img
                                        src={bin_icon}
                                        alt=""
                                        style={{ cursor: "pointer" }}
                                      />
                                    </div>
                                  </div>
                                  <div className={classes.email_detail_frd_img}>
                                    <img
                                      src={forward_img1}
                                      alt=""
                                      style={{ cursor: "pointer" }}
                                    />
                                    <div className={classes.normal_line}></div>
                                    <img
                                      src={forward_img2}
                                      alt=""
                                      style={{ cursor: "pointer" }}
                                    />
                                    <div className={classes.normal_line}></div>
                                    <img
                                      src={forward_img3}
                                      alt=""
                                      style={{ cursor: "pointer" }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </Box>
                          </Paper>
                          <Paper elevation={3} className={classes.emailViewer}>
                            {/* Email Metadata */}

                            {/* Email Text Content */}

                            {/* Email HTML Content */}
                            {data.htmlContent ? (
                              <Box className={classes.htmlContent}>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: removeGmailQuote(data.htmlContent),
                                  }}
                                />
                              </Box>
                            ) : (
                              <Box className={classes.htmlContent}>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: convertNewlinesToBr(
                                      data.textContent
                                    ), // Call the function directly
                                  }}
                                />
                              </Box>
                            )}

                            {/* Attachments */}
                            {data.attachments?.length > 0 && (
                              <Box className={classes.attachments}>
                                <Typography variant="subtitle1">
                                  Attachments:
                                </Typography>
                                {data.attachments.map((attachment, index) => (
                                  <Button
                                    key={index}
                                    variant="contained"
                                    href={`${process.env.REACT_APP_API_URL}/api/${attachment.url}`}
                                    target="_blank"
                                  >
                                    {attachment.fileName}
                                  </Button>
                                ))}
                              </Box>
                            )}
                          </Paper>
                        </>
                      );
                    })}
                  </div>
                  <>
                    <div className={classes.suggestion_content}>
                      {message?.map((data, index) => (
                        <div
                          key={index}
                          className={classes.suggestion_text}
                          onClick={() => handelsuggestion(data, index)}
                        >
                          {data}
                        </div>
                      ))}
                    </div>
                    <Paper className={classes.suggestion_container}>
                      <div className={classes.send_icon}>
                        {replayLoading ? (
                          <RotatingLines
                            visible={true}
                            height="30"
                            width="30"
                            color="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            strokeColor="#7c7c7c"
                            ariaLabel="rotating-lines-loading"
                          />
                        ) : (
                          <img
                            src={send_icon}
                            alt="send icon"
                            onClick={handleSendMail}
                          />
                        )}
                      </div>

                      <TextareaAutosize
                        placeholder="Reply"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        className={classes.mail_single_input}
                        disabled={replayLoading}
                      />
                    </Paper>
                  </>
                </>
              ) : (
                ""
              )}
            </Box>
          </>
        }
        {questionSide && (
          <>
            <ValidatorForm
              className={classes.suggestion_container_form}
              onSubmit={handleSubmit}
            >
              {qusetion?.map((data, index) => {
                return (
                  <div key={index}>
                    <FormLabel>{data}</FormLabel>
                    <TextValidator
                      placeholder="Enter your answer"
                      value={formData[index] || ""} // Set the value for the TextValidator
                      onChange={(event) => handleInputChange(index, event)}
                      validators={["required"]}
                      errorMessages={["This field is required"]}
                    />
                  </div>
                );
              })}

              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{ marginTop: "20px" }}
                disabled={draftLoading}
              >
                {draftLoading ? "Sending..." : "Submit"}
              </Button>
              {draftMessage ? (
                <>
                  {
                    <div
                      dangerouslySetInnerHTML={{
                        __html: convertNewlinesToBr(draftMessage), // Call the function directly
                      }}
                    />
                  }

                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginTop: "20px" }}
                    type="button"
                    onClick={handleAdd}
                  >
                    Add
                  </Button>
                </>
              ) : null}
            </ValidatorForm>
          </>
        )}
        {aiComponent && (
          <Aicomponent
            setAiComponent={setAiComponent}
            setMailDetail={setMailDetail}
            mailDetail={mailDetail}
            getMailDetall={getMailDetall}
          />
        )}
      </Box>
    </>
  );
};

export default EmailIndex;
