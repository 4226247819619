// MessageInput.js
import { Box, TextField, InputAdornment, Tooltip } from "@mui/material";
import { EmojiIcon, SendIcon, UploadIcon } from "../../assets/icons/icons";
import { uploadFile } from "../../api/Api";
import { useDispatch } from "react-redux";
import { errorState } from "../../redux/reducers/UserReducer";

const MessageInput = ({
  value,
  setValue,
  showPicker,
  myFile,
  setMyFile,
  setShowPicker,
  handleSendMsg,
  handleKeyPress,
}) => {
  const dispatch = useDispatch();

  async function handleImage(e) {
    let my_image = e?.target?.files[0];
    let file_name = my_image?.name?.split(".")[1].toLowerCase();

    if (
      ["png", "jpg", "jpeg", "mp3"].includes(file_name) &&
      my_image?.size < 10000000
    ) {
      const reader = new FileReader();
      reader.onload = () => {
        console.log("reader.result =");
      };
      reader.readAsDataURL(my_image);

      let my_file = new FormData();
      my_file.append("file", my_image);

      let result = await uploadFile(my_file);
      if (result?.status === 201 || result?.status === 200) {
        setMyFile({
          ...myFile,
          file_name: result?.data?.data?.originalname,
          file_url: result?.data?.data?.filename,
          file_type: result?.data?.data?.mimetype,
          file_size: result?.data?.data?.size,
        });

        setTimeout(() => {
          handleSendMsg(result?.data?.data);
        }, 1000);
      } else {
        dispatch(
          errorState(
            result?.data?.message ? result?.data?.message : result?.statusText
          )
        );
      }
    } else {
      alert("Accepts only jpg, jpeg, png and audio files with max 10mb");
    }
  }

  return (
    <Box bgcolor={"white"} borderRadius={"12px"}>
      <TextField
        fullWidth
        placeholder="Type a message"
        autoComplete="off"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={handleKeyPress}
        variant="outlined" // Specify the variant
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: "none", // Removes the outline
            },
            "&:hover fieldset": {
              border: "none", // Removes the outline on hover
            },
            "&.Mui-focused fieldset": {
              border: "none", // Removes the outline when focused
            },
            borderRadius: "8px", // Set your desired border radius
          },
          // Adding styles directly to the input element for border radius
          "& .MuiOutlinedInput-input": {
            borderRadius: "8px", // Apply border radius to the input element
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Box onClick={() => setShowPicker(!showPicker)}>
                <EmojiIcon />
              </Box>
              <Tooltip title="Upload image / audio" placement="top" arrow>
                <Box ml={1}>
                  <input
                    id="file-input"
                    type="file"
                    onChange={handleImage}
                    hidden
                    multiple
                    accept="image/png, image/jpeg, image/jpg, audio/*"
                  />
                  <label htmlFor="file-input">
                    <UploadIcon />
                  </label>
                </Box>
              </Tooltip>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end" onClick={handleSendMsg}>
              <SendIcon />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default MessageInput;
