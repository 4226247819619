import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  TextField,
  Button,
  FormHelperText,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import classes from "./compose.module.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import FilePicker from "./FilePicker";
import { composeMail, composeMailByAi, getMyEmailsList } from "../../api/Api";
import AiMailModal from "./AiMailModal";
import { IoClose } from "react-icons/io5";
import { toast } from "react-toastify";
import { RotatingLines } from "react-loader-spinner";

const ComposeMail = ({
  open,
  setOpen,
  mailDetail,
  setAiComponent,
  setGetMailDetail,
}) => {
  const handleClose = () => setOpen(false);
  const [aiModelOpen, setAiModelOpen] = useState(false);
  const [selectFile, setSelectFile] = useState(null);
  const [myEmailsList, setMyEmailsList] = useState([]);
  const [loading, setloading] = useState(false);
  const [formData, setFormData] = useState({
    from: "",
    to: "",
    subject: "",
    emailData: "",
  });
  const [errors, setErrors] = useState({
    from: "",
    to: "",
    subject: "",
    emailData: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({ ...errors, [name]: "" });
  };

  const handleQuillChange = (value) => {
    setFormData((prev) => ({
      ...prev,
      emailData: value,
    }));
    setErrors((prev) => ({
      ...prev,
      emailData: "",
    }));
  };

  const handleSubmit = async () => {
    setloading(true);
    let newErrors = {};
    if (!formData.from.trim()) {
      newErrors.from = "From is required.";
    }
    if (!formData.to.trim()) {
      newErrors.to = "To is required.";
    }
    if (!formData.subject.trim()) {
      newErrors.subject = "Subject is required.";
    }
    if (!formData.emailData.trim()) {
      newErrors.emailData = "Email content is required.";
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const submissionData = new FormData();
    submissionData.append("from", formData.from);
    submissionData.append("to", formData.to);
    submissionData.append("subject", formData.subject);
    submissionData.append("emailData", formData.emailData);

    if (selectFile) {
      submissionData.append("file", selectFile);
    }
    try {
      await composeMail(submissionData);
      toast.success("Email sent successfully");
      setloading(false);
    } catch (error) {
      console.log(error);
      toast.error("Failed to send email");
      setloading(false);
    }
    setFormData({ from: "", to: "", subject: "", emailData: "" });
    setSelectFile(null);
  };

  const handleFileSelected = (file) => {
    console.log("Selected file:", file);
    setSelectFile(file);
  };

  const formatText = (input) => {
    return input ? input.replace(/\n/g, "<br>").replace(/\*/g, "") : "";
  };

  const getEmail = async () => {
    try {
      const data = await getMyEmailsList();
      const getEmails = data?.data?.data.map((data) => ({
        email: data.email,
      }));
      setMyEmailsList(getEmails);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getEmail();
  }, []);

  useEffect(() => {
    if (mailDetail && Object.keys(mailDetail).length > 0) {
      setFormData({
        to: mailDetail.to || "",
        subject: mailDetail.subject || "",
        emailData: formatText(mailDetail.body || ""),
      });
    }
  }, [mailDetail]);

  const openAiModal = () => {
    setAiComponent(true);
    setGetMailDetail(formData);
  };

  return (
    <>
      <div
        open={open}
        onClose={handleClose}
        style={{ width: "100%", height: "100%" }}
      >
        {open && (
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: "100%",
              backgroundColor: "white",
              borderRadius: 2,
              border: "none",
              zIndex: 4,
              display: "flex",
              flexDirection: "column",
              gap: 0.5,
              borderRadius: 3,
            }}
          >
            <div className="position-relative">
              <div className={classes.compose_mail_close}>
                <IoClose
                  fontSize={20}
                  cursor={"pointer"}
                  onClick={() => setOpen(false)}
                  style={{
                    marginTop: "10px",
                    marginRight: "10px",
                    color: "white",
                  }}
                />
              </div>
              <div className={classes.compose_mail_head}>
                <p>New Mail</p>
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  size="medium"
                  style={{ backgroundColor: "white" }}
                  disabled={loading}
                >
                  {loading ? (
                    <RotatingLines
                      visible={true}
                      height="30"
                      width="30"
                      color="grey"
                      strokeWidth="5"
                      strokeColor="#0062eb"
                      animationDuration="0.75"
                      ariaLabel="rotating-lines-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  ) : (
                    "Send"
                  )}
                </Button>
              </div>
            </div>
            <div style={{ padding: "30px" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1.5,
                }}
              >
                <Select
                  name="from"
                  value={formData.from}
                  onChange={handleChange}
                  displayEmpty
                  error={Boolean(errors.from)}
                  size="small"
                  variant="standard"
                  classes={classes.compose_border_color}
                >
                  <MenuItem value="" disabled style={{ color: "red" }}>
                    Select sender email
                  </MenuItem>
                  {myEmailsList.map((emailObj, index) => (
                    <MenuItem key={index} value={emailObj.email}>
                      {emailObj.email}
                    </MenuItem>
                  ))}
                </Select>
                {errors.from && (
                  <FormHelperText error>{errors.from}</FormHelperText>
                )}

                <TextField
                  label={errors.to ? errors.to : "To"}
                  name="to"
                  value={formData.to}
                  onChange={handleChange}
                  size="small"
                  variant="standard"
                  error={Boolean(errors.to)}
                />

                <TextField
                  label={errors.subject ? errors.subject : "Subject"}
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  size="small"
                  variant="standard"
                  error={Boolean(errors.subject)}
                />

                <div>
                  <ReactQuill
                    value={formData.emailData}
                    onChange={handleQuillChange}
                    className={classes.react_quill}
                    placeholder="Compose your email here..."
                  />
                  {errors.emailData && (
                    <FormHelperText error>{errors.emailData}</FormHelperText>
                  )}
                </div>
                <div className="d-flex gap-2 ">
                  <div>
                    <FilePicker onFileSelected={handleFileSelected} />
                    {selectFile && <Typography>{selectFile?.name}</Typography>}
                  </div>
                  <Button onClick={openAiModal}>Create mail using AI</Button>
                </div>
              </Box>
            </div>
          </Box>
        )}
      </div>
    </>
  );
};

export default ComposeMail;
